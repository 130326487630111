import * as React from 'react';

import Hero from './Hero.style';

const Container = () => {
  return (
    <Hero id="home">
      <div className="container">
        <div id="hero">
          <h1>FULL STACK DEVELOPER</h1>
          <p>
          Experienced software engineer since 2014, proficient in a diverse technology stack, including Node.js, Go
          Lang, PHP, React.js, and Angular.js. Strong command over the database management with MySQL,
          PostgreSQL, coupled with expertise in AWS services. Skilled in version control using GitHub and GitLab.
          Seeking opportunities to leverage this skill set to develop innovative, scalable, and efficient software
          solutions. <a href="mailto:rowel143deguzman@gmail.com">Here</a>
          </p>

          <div>
            <a className="btn site" href="mailto:rowel143deguzman@gmail.com?subject=I want a website">
              I Want Website! <span className="glyphicon glyphicon-chevron-right" />
            </a>
            <a className="btn hire" href="mailto:rowel143deguzman@gmail.com?subject=I want to hire">
              I Want to Hire! <span className="glyphicon glyphicon-chevron-right" />
            </a>
          </div>
        </div>
      </div>
    </Hero>
  )
}
export default Container;