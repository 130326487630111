import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import favicon from "Resources/images/favicon.ico";

class SEO extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object,
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
  }

  static defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
  }

  render() {

    const { data, description, lang, meta, title, keywords } = this.props;

    const { site } = data;
    const metaDescription = description || site.siteMetadata.description;
    const metaKeywords = keywords || site.siteMetadata.keywords;
    
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        link={[
          { rel: 'shortcut icon', type: 'image/ico', href: favicon }
        ]}
        meta={[
          {
            name: `viewport`,
            content: site.siteMetadata.viewport
          },
          {
            name: `description`,
            content: metaDescription,
          },
          { 
            name: 'keywords',
            content: metaKeywords
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
    )
  }
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author,
        keywords,
        viewport
      }
    }
  }
`

export default (props) => (
  <StaticQuery query={detailsQuery} render={data => <SEO data={data} {...props} />} />
)