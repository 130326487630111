import * as React from 'react';

import SEO from "../core/seo"

import Header from './Header';
import Hero from './Hero';

const Container = () => {
  return (
    <>
      <SEO title="Rowel de Guzman | Portfolio | Personal Page"/>
      <div className="cover" />
      
      <Header />

      <main>
        <Hero />
      </main>
    </>
  )
};

export default Container