import React from "react";

import Header from './Header.style';

const Container = () => {
  return (
    <Header>
        
      <div className="header">

        <div className="brand">
          <div className="name">Rowel de Guzman</div>
          <div className="work">Full Stack developer</div>
          <div className="work">rowel143deguzman@gmail.com</div>
        </div>

        <div className="navigation">
          <a href="https://github.com/roweldeguzman" rel="noopener noreferrer" target="_blank">
            <i className="zmdi zmdi-github-box zmdi-hc-fw"/>
          </a>
          <a href="https://www.linkedin.com/in/rowel-guzman/" rel="noopener noreferrer" target="_blank">
            <i className="zmdi zmdi-linkedin-box zmdi-hc-fw"/>
          </a>
          <a href="mailto:rowel.deguzman@roweldev.com">
            <i className="zmdi zmdi-email zmdi-hc-fw"/>
          </a>
      
        </div>

      </div>
    </Header>
  )
}

export default Container;